exports.components = {
  "component---src-components-articles-tsx": () => import("./../../../src/components/articles.tsx" /* webpackChunkName: "component---src-components-articles-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-allnews-tsx": () => import("./../../../src/pages/Allnews.tsx" /* webpackChunkName: "component---src-pages-allnews-tsx" */),
  "component---src-pages-allstudy-tsx": () => import("./../../../src/pages/Allstudy.tsx" /* webpackChunkName: "component---src-pages-allstudy-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-done-seminer-tsx": () => import("./../../../src/pages/DoneSeminer.tsx" /* webpackChunkName: "component---src-pages-done-seminer-tsx" */),
  "component---src-pages-greeting-tsx": () => import("./../../../src/pages/Greeting.tsx" /* webpackChunkName: "component---src-pages-greeting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-meaning-tsx": () => import("./../../../src/pages/Meaning.tsx" /* webpackChunkName: "component---src-pages-meaning-tsx" */),
  "component---src-pages-nintei-tsx": () => import("./../../../src/pages/Nintei.tsx" /* webpackChunkName: "component---src-pages-nintei-tsx" */),
  "component---src-pages-study-tsx": () => import("./../../../src/pages/Study.tsx" /* webpackChunkName: "component---src-pages-study-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/Support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-teikan-tsx": () => import("./../../../src/pages/Teikan.tsx" /* webpackChunkName: "component---src-pages-teikan-tsx" */),
  "component---src-pages-volunteer-tsx": () => import("./../../../src/pages/Volunteer.tsx" /* webpackChunkName: "component---src-pages-volunteer-tsx" */),
  "component---src-pages-wvc-tsx": () => import("./../../../src/pages/Wvc.tsx" /* webpackChunkName: "component---src-pages-wvc-tsx" */)
}

